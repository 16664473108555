.spinner {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    background: #bfe8fb;
    border-radius: 50%;
    opacity: 1;
  }

  .spinner.fadeOut {
    opacity: 0;
    transition: opacity 300ms;
  }

  .spinner::after {
    content: "";
    width: 92%;
    height: 92%;
    background: #fff;
    border-radius: 50%;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .spinner::before {
    content: "";
    height: 0;
    width: 0;
    border-radius: 50%;
    border-right: 50px solid #0C99DB;
    border-top: 50px solid transparent;
    border-left: 50px solid transparent;
    border-bottom: 50px solid transparent;
    position: absolute;
    animation: rotate 1.6s infinite;
  }

  @keyframes rotate {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }