html {
  font-kerning: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html, body, #root {
  height: 100%;
}

@media (min-width: 768px) {
  .vh-md-100 {
    height: 100vh !important;
  }
}
