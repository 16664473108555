.PageContainer {
  overflow: hidden;
  max-width: 450px;
}
.Page {
  flex: 1 0 100%;
  padding: 0.2rem;
}
.Page.Page-enter {
  order: 1;
  transform: translate3d(0, 0, 0);
  opacity: 0;
}
.Page.Page-enter.Page-enter-active {
  opacity: 1;
  transition: transform 300ms ease-out 100ms, opacity 100ms 100ms;
  transform: translate3d(-100%, 0, 0);
}
.Page.Page-exit {
  order: 0;
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.Page.Page-exit.Page-exit-active {
  opacity: 0;
  transition: transform 300ms ease-in, opacity 100ms 200ms;
  transform: translate3d(-100%, 0, 0);
}
