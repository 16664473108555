.RequestAccessLink {
  text-align: center;
  margin-bottom: 35px;
}

.RequestAccessLink-Or {
  align: text-center;
  background: #eef2f5;
  margin: -1.4rem auto 2rem;
  text-align: center;
  width: 3rem;
  color: #9e9ea6;
  font-size: 1rem;
}

